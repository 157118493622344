@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Inter', sans-serif;
}

.btn-gradient {
  background-size: 200% auto;
  background-image: linear-gradient(45deg, rgba(24, 5, 6, 1) 0%, rgba(131, 26, 10, 1) 51%, rgba(24, 5, 6, 1) 100%);
}

.banner-gradient {
  background-image: linear-gradient(103deg, rgba(24, 5, 6, 1) 0%, rgba(131, 26, 10, 1) 51%, rgba(152, 38, 15, 1) 100%);
}

.border-gradient {
  position: relative;
  z-index: 0;
}

.border-gradient:before {
  content: "";
  position: absolute;
  z-index: -1;
  inset: 0;
  background: linear-gradient(to right, rgba(255, 203, 102, 1), rgba(254, 254, 228, 1) 100%);
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.text-shadow {
  text-shadow: 0 0 6px rgba(255, 255, 255, 0.5);
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide-in {
  animation: slideIn 0.3s ease-in-out;
}

.rotate-icon {
  transform: rotate(180deg);
  transition: transform 0.3s ease-in-out;
}

.banner {
  position: relative;
  display: inline-block;
  height: 36px;
  margin: 0 1rem;
}

.content {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  height: 100%;
  background-image: linear-gradient(103deg, rgba(24, 5, 6, 1) 0%, rgba(131, 26, 10, 1) 51%, rgba(152, 38, 15, 1) 100%);
}

.content:before {
  content: ' ';
  display: block;
  background: rgba(24, 5, 6, 1);
  width: 30px;
  height: 100%;
  position: absolute;
  top: 0;
  left: -15px;
  transform: skew(35deg);
  border-top-left-radius: 25px;
  border-bottom-left-radius: 5px;
  z-index: -1;
  border: 2px solid rgba(255, 203, 102, 1);
}

.content:after {
  content: ' ';
  display: block;
  background: rgba(152, 38, 15, 1);
  width: 30px;
  height: 100%;
  position: absolute;
  top: 0;
  right: -15px;
  transform: skew(-35deg);
  border-top-right-radius: 25px;
  border-bottom-right-radius: 5px;
  z-index: -1;
  border: 2px solid rgba(255, 203, 102, 1);
}

span {
  position: relative;
  display: inline-block;
  color: #fff;
  transform: skewX(0deg);
}

/* Radio Button styling */
/* CSS for .radio */
.radio {
  margin: 16px 0;
  display: block;
  cursor: pointer;
}

.radio input {
  display: none;
}

.radio input + span {
  line-height: 22px;
  height: 22px;
  padding-left: 22px;
  display: block;
  position: relative;
}

.radio input + span:not(:empty) {
  padding-left: 28px;
}

.radio input + span:before,
.radio input + span:after {
  content: '';
  width: 18px;
  height: 18px;
  display: block;
  border-radius: 50%;
  left: 0;
  top: 0;
  position: absolute;
}

.radio input + span:before {
  background: rgba(255, 255, 255, 0.15);
  transition: background 0.2s ease, transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
}

.radio input + span:after {
  background: black;
  transform: scale(0.78);
  transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
}

.radio input:checked + span:before {
  transform: scale(1.04);
  background: white;
}

.radio input:checked + span:after {
  transform: scale(0.4);
  transition: transform 0.3s ease;
}

.radio:hover input + span:before {
  transform: scale(0.92);
}

.radio:hover input + span:after {
  transform: scale(0.74);
}

.radio:hover input:checked + span:after {
  transform: scale(0.4);
}

.radio input:checked + span:before {
  transform: scale(1.04);
  background: white;
}

.radio input:checked + span:after {
  transform: scale(0.4);
  transition: transform 0.3s ease;
}

.radio.selected input:checked + span:before {
  background: black;
}

.radio.selected input:checked + span:after {
  transform: scale(1);
}